
  import { computed, defineComponent, ref, getCurrentInstance } from "vue"
  import { CollectionResourceDoc, RelationshipsWithData, ResourceObject } from '@/models/jsonapi'
  import { Suggestion } from "@/models/care_stays";
  import { BvTableCtxObject } from "bootstrap-vue"
  import { localIndex, fetchRelated } from "@/utils/apiDataHelper"
  import { resourceUrl } from '@/utils/dataExtractors'
  import Search from "@/components/shared/Search.vue"
  import { internalCareStayIndexColumns } from '@/view_scripts/table_columns/care_stay_columns'

  export default defineComponent({
    name: 'internal_care_stays_index',
    setup(_) {
      const root = getCurrentInstance().proxy
      const totalItems = computed<number>(() => root.$store.state.care_stays.pagination.totalItems)
      const data = ref<any[]>([])
      const getItems = async(ctx: BvTableCtxObject) => {
        const careStayQueryParams = {
          'page[number]': ctx.currentPage,
          'page[size]': ctx.perPage,
          'sort': '-createdAt',
          'include': 'care_giver_instance.care_giver,visor.agency,visor.posting,visor.contract',
          'fields[agencies]': 'name',
          'fields[care_givers]': 'first_name,last_name',
          'fields[care_stays]': 'all',
          'relationships': 'visor,care_giver_instance',

        }
        const careStayResponse = await root.$store.dispatch('care_stays/load2', careStayQueryParams)
        const index = localIndex(careStayResponse)
        const result = careStayResponse.data.map((cs: any) => {
          cs.attributes.agency = fetchRelated(cs, 'visor.agency', index)
          cs.attributes.cg = fetchRelated(cs, 'careGiverInstance.careGiver', index)
          cs.attributes.posting = fetchRelated(cs, 'visor.posting', index)
          cs.attributes.contract = fetchRelated(cs, 'visor.contract', index)
          return cs
        })
        // const segmentIds = extractRelationshipIds(careStayResponse, 'segments')
        // const cgiIds = extractRelationshipIds(careStayResponse, 'careGiverInstance')
        // const segmentQueryParams = { 'filter[id]':segmentIds.join(','),  'page[size]': segmentIds.length*2 }
        // const cgiQueryParams = { 'filter[id]':cgiIds.join(','), 'page[size]': segmentIds.length*2, include: 'care_giver' }
        // const [segmentResponse, cgiResponse] = await Promise.all([root.$store.dispatch('segments/load', segmentQueryParams), root.$store.dispatch('care_giver_instances/load', cgiQueryParams)])
        // mapApiDataMerger(careStayResponse.data, segmentResponse.data, 'segments')
        // mapApiDataMerger(careStayResponse.data, cgiResponse.data, 'careGiverInstance')
        //mapApiDataMerger(careStayResponse.data, segmentResponse.data, 'segment')
        data.value =  result
        return result
      }

      // const columns = ref(providerSuggestionIndexColumns)

      const searchFunction = async (query: {[key: string]: string}) => {
        return root.$store.dispatch('contracts/search', query)
      }

      const searchColumns = [
        // {
        //   component: () => import("@/components/shared/table/cells/LinkCell.vue"),
        //   key: "meta.designation",
        //   title: "Haushalt",
        //   options: {hrefFunction: (data: any) => resourceUrl(data.item)}
        // }
      ]

      return {
        data,
        getItems,
        totalItems,
        columns: internalCareStayIndexColumns,
        searchColumns,
        searchFunction
      }
    }
  })
