import { fullName, resourceUrl } from '@/utils/dataExtractors'
export const careStayColumns = {}
export const internalCareStayColumns = (actions: { [key: string]: Function }) => {
  return [
    {
      component: () => import("@/components/shared/table/cells/ValueCell.vue"),
      title: "Status",
      key: 'attributes.stage',
      sortable: true,
    },
    {
      component: () => import("@/components/shared/table/cells/ValueCell.vue"),
      title: "Agentur",
      key: 'meta.agencyName'
    },
    {
      component: () => import("@/components/shared/table/cells/LinkCell.vue"),
      title: "BK",
      key: 'meta.cGName',
      options: {
        hrefFunction: (data: any) => {
          return `/care_giver_instances/${data.item.meta.cgiId}`
        }
      }
    },
    {
      component: () => import("@/components/shared/table/cells/ValueCell.vue"),
      label: "Erstellt",
      title: "Erstellt",
      key: 'attributes.createdAt',
      options: { type: 'datetime' }
    },
    {
      component: () => import("@/components/shared/table/cells/ValueCell.vue"),
      label: "Vorgestellt",
      title: "Vorgestellt",
      key: 'attributes.presentedAt',
      options: { type: 'datetime' }
    },
    {
      component: () => import("@/components/shared/table/cells/ValueCell.vue"),
      label: "Start",
      title: "Start",
      key: 'attributes.billStart',
      options: { type: 'date' }
    },
    {
      component: () => import("@/components/shared/table/cells/ValueCell.vue"),
      title: "Ende",
      key: 'attributes.billEnd',
      options: { type: 'date' },
      seller: true
    },
    {
      component: () => import("@/components/shared/table/cells/ValueCell.vue"),
      label: "Ankunft",
      title: "Ankunft",
      key: 'attributes.arrival',
      sortable: true,
      options: { type: 'date' }
    },
    {
      component: () => import("@/components/shared/table/cells/ValueCell.vue"),
      label: "Abreise",
      title: "Abreise",
      key: 'attributes.departure',
      sortable: true,
      options: { type: 'date' }
    },
    {
      component: () => import("@/components/shared/table/cells/LinkCell.vue"),
      title: "Vertrag",
      key: 'meta.contract',
      options: {
        contentFunction: (data: any) => (data.item.meta.contractId ? 'Zum Vertrag' : 'Neuvorschlag'),
        hrefFunction: (data: any) => (`/contracts/${data.item.meta.contractId}`),
        cellClassFunction: (data: any) => (data.item.meta.contractId ? '' : 'text-warning')
      }
    },
    {
      component: () => import("@/components/shared/table/cells/ValueCell.vue"),
      label: "Preis",
      title: "Preis",
      key: 'attributes.price',
    },
    {
      component: () => import("@/components/shared/table/cells/ValueCell.vue"),
      label: "2PHH",
      title: "2PHH",
      key: 'attributes.dubHh',
    },
    {
      component: () => import("@/components/shared/table/cells/ValueCell.vue"),
      label: "2PBP",
      title: "2PBP",
      key: 'attributes.dubCr',
    },
    {
      component: () => import("@/components/shared/table/cells/ValueCell.vue"),
      label: "Nachtdienst",
      title: "Nachtdienst",
      key: 'attributes.nightCare',
    },
    {
      component: () => import("@/components/shared/table/cells/ValueCell.vue"),
      label: "Führerschein",
      title: "Führerschein",
      key: 'attributes.driversLicense',
    },
    {
      component: () => import("@/components/shared/table/cells/ButtonsCell.vue"),
      key: "actions",
      title: "Actions",
      sortable: false,
      options: {
        buttons: [
          {
            title: 'Bearbeiten',
            icon: ['fas', 'pencil-alt'],
            action: actions.editCareStay,
          },
          {
            title: 'Abbrechen',
            icon: ['far', 'ban'],
            action: actions.abortCareStay,
          },
          {
            title: 'Segmente',
            icon: ['fas', 'eye'],
            action: actions.selectCareStaySegments,
          }
        ]
      }
    },
  ]
}

export const internalCareStayIndexColumns = [
  {
    component: () => import("@/components/shared/table/cells/ValueCell.vue"),
    title: "Status",
    key: 'attributes.stage',
    sortable: true,
  },
  {
    component: () => import("@/components/shared/table/cells/ValueCell.vue"),
    title: "Agentur",
    key: 'attributes.agency.attributes.name'
  },
  {
    component: () => import("@/components/shared/table/cells/LinkCell.vue"),
    title: "Vertrag",
    key: 'attributes.contract',
    options: {
      contentFunction: (data: any) =>{
        const attr = data.item.attributes
        return attr.isSwap ? 'Zum Vertrag' : 'Zur Stelle'
      },
      hrefFunction: (data: any) => {
        const attr = data.item.attributes
        return attr.isSwap ? `/contracts/${data.item.attributes?.contract?.id}` : `/postings/${data.item.attributes?.posting?.id}`
      },
      cellClassFunction: (data: any) => (data.item.attributes.isSwap ? 'text-success' : 'text-warning')
    }
  },
  {
    component: () => import("@/components/shared/table/cells/LinkCell.vue"),
    title: "BK",
    key: 'attributes.cg.attributes.lastName',
    options: {
      contentFunction: (data: any) => `${data.item.attributes.cg?.attributes?.firstName} ${data.item.attributes.cg?.attributes?.lastName}`.trim(),
      hrefFunction: (data: any) => `/care_giver_instances/${data.item.relationships?.careGiverInstance?.data?.id}`
    }
  },
  {
     component: () => import("@/components/shared/table/cells/ValueCell.vue"),
     label: "Erstellt",
     title: "Erstellt",
     key: 'attributes.createdAt',
     options: { type: 'datetime' }
   },
   {
     component: () => import("@/components/shared/table/cells/ValueCell.vue"),
     label: "Vorschlag",
     title: "Vorschlag",
     key: 'attributes.presentedAt',
     options: { type: 'datetime' }
   },
  {
    component: () => import("@/components/shared/table/cells/ValueCell.vue"),
    label: "Start",
    title: "Start",
    key: 'attributes.billStart',
    sortable: true,
    options: { type: 'date' }
  },
  {
    component: () => import("@/components/shared/table/cells/ValueCell.vue"),
    title: "Ende",
    key: 'attributes.billEnd',
    options: { type: 'date' },
    seller: true
  },
  {
    component: () => import("@/components/shared/table/cells/ValueCell.vue"),
    label: "Ankunft",
    title: "Ankunft",
    key: 'attributes.arrival',
    sortable: true,
    options: { type: 'date' }
  },
  {
    component: () => import("@/components/shared/table/cells/ValueCell.vue"),
    label: "Abreise",
    title: "Abreise",
    key: 'attributes.departure',
    sortable: true,
    options: { type: 'date' }
  },
  {
    component: () => import("@/components/shared/table/cells/ValueCell.vue"),
    label: "ProvPfS",
    title: "ProvPfS",
    key: 'attributes.provPfs',
  },
  {
    component: () => import("@/components/shared/table/cells/ValueCell.vue"),
    label: "ProvSeller",
    title: "ProvSeller",
    key: 'attributes.provSeller',
  },
  {
    component: () => import("@/components/shared/table/cells/ValueCell.vue"),
    label: "DiscountSeller",
    title: "DiscountSeller",
    key: 'attributes.discountSeller',
  },
  {
    component: () => import("@/components/shared/table/cells/ValueCell.vue"),
    label: "Absage-/AbbruchsGrund",
    title: "Absage-/AbbruchsGrund",
    key: 'rejectionReason',
    options: {
      contentFunction: (data: any) => {
        const atts = data.item.attributes
        return (atts.stage === 'Abgelehnt' ? atts.rejectionReason : atts.cancelReason || '')
      }
    }
  },
  // {
  //   component: () => import("@/components/shared/table/cells/LinkCell.vue"),
  //   title: "Vertrag",
  //   key: 'meta.contract',
  //   options: {
  //     contentFunction: (data: any) => (data.item.meta.contractId ? 'Zum Vertrag' : 'Neuvorschlag'),
  //     hrefFunction: (data: any) => (`/contracts/${data.item.meta.contractId}`),
  //     cellClassFunction: (data: any) => (data.item.meta.contractId ? '' : 'text-warning')
  //   }
  // },
  {
    component: () => import("@/components/shared/table/cells/ValueCell.vue"),
    label: "Preis",
    title: "Preis",
    key: 'attributes.price',
  },
  {
    component: () => import("@/components/shared/table/cells/ValueCell.vue"),
    label: "2PHH",
    title: "2PHH",
    key: 'attributes.dubHh',
  },
  {
    component: () => import("@/components/shared/table/cells/ValueCell.vue"),
    label: "2PBP",
    title: "2PBP",
    key: 'attributes.dubCr',
  },
  {
    component: () => import("@/components/shared/table/cells/ValueCell.vue"),
    label: "Nachtdienst",
    title: "Nachtdienst",
    key: 'attributes.nightCare',
  },
  {
    component: () => import("@/components/shared/table/cells/ValueCell.vue"),
    label: "Führerschein",
    title: "Führerschein",
    key: 'attributes.driversLicense',
  },
  // {
  //   component: () => import("@/components/shared/table/cells/ButtonsCell.vue"),
  //   key: "actions",
  //   title: "Actions",
  //   sortable: false,
  //   options: {
  //     buttons: [
  //       {
  //         title: 'Bearbeiten',
  //         icon: ['fas', 'pencil-alt'],
  //         action: actions.editCareStay,
  //       },
  //       {
  //         title: 'Abbrechen',
  //         icon: ['far', 'ban'],
  //         action: actions.abortCareStay,
  //       },
  //       {
  //         title: 'Segmente',
  //         icon: ['fas', 'eye'],
  //         action: actions.selectCareStaySegments,
  //       }
  //     ]
  //   }
  // },
]

export const sellerCareStayColumns = (actions: { [key: string]: Function }) => {
  return [
    {
      component: () => import("@/components/shared/table/cells/ValueCell.vue"),
      title: "Status",
      key: 'attributes.stage',
      sortable: true,
    },
    {
      component: () => import("@/components/shared/table/cells/ValueCell.vue"),
      title: "Agentur",
      key: 'meta.agencyName'
    },
    {
      component: () => import("@/components/shared/table/cells/LinkCell.vue"),
      title: "BK",
      key: 'meta.cGName',
      options: { hrefFunction: (data: any) => (`/care_giver_instances/${data.item.meta.cgiId}`) }
    },
    {
      component: () => import("@/components/shared/table/cells/ValueCell.vue"),
      label: "Start",
      title: "Start",
      key: 'attributes.billStart',
      sortable: true,
      options: { type: 'date' }
    },
    {
      component: () => import("@/components/shared/table/cells/ValueCell.vue"),
      title: "Ende",
      key: 'attributes.billEnd',
      options: { type: 'date' },
      seller: true
    },
    {
      component: () => import("@/components/shared/table/cells/ValueCell.vue"),
      label: "Ankunft",
      title: "Ankunft",
      key: 'attributes.arrival',
      sortable: true,
      options: { type: 'date' }
    },
    {
      component: () => import("@/components/shared/table/cells/ValueCell.vue"),
      label: "Abreise",
      title: "Abreise",
      key: 'attributes.departure',
      sortable: true,
      options: { type: 'date' }
    },
    {
      component: () => import("@/components/shared/table/cells/LinkCell.vue"),
      title: "Vertrag",
      key: 'meta.contract',
      options: {
        contentFunction: (data: any) => (data.item.meta.contractId ? 'Zum Vertrag' : 'Neuvorschlag'),
        hrefFunction: (data: any) => (`/contracts/${data.item.meta.contractId}`),
        cellClassFunction: (data: any) => (data.item.meta.contractId ? '' : 'text-warning')
      }
    },
    {
      component: () => import("@/components/shared/table/cells/ValueCell.vue"),
      label: "Preis",
      title: "Preis",
      key: 'meta.totalPrice',
    },
    {
      component: () => import("@/components/shared/table/cells/ValueCell.vue"),
      label: "Rabatt VP",
      title: "Rabatt VP",
      key: 'attributes.discountSeller',
    },
    {
      component: () => import("@/components/shared/table/cells/ValueCell.vue"),
      label: "2PHH",
      title: "2PHH",
      key: 'attributes.dubHh',
    },
    {
      component: () => import("@/components/shared/table/cells/ValueCell.vue"),
      label: "2PBP",
      title: "2PBP",
      key: 'attributes.dubCr',
    },
    {
      component: () => import("@/components/shared/table/cells/ValueCell.vue"),
      label: "Nachtdienst",
      title: "Nachtdienst",
      key: 'attributes.nightCare',
    },
    {
      component: () => import("@/components/shared/table/cells/ValueCell.vue"),
      label: "Führerschein",
      title: "Führerschein",
      key: 'attributes.driversLicense',
    },
    {
      component: () => import("@/components/shared/table/cells/ButtonsCell.vue"),
      key: "actions",
      title: "Actions",
      sortable: false,
      options: {
        buttons: [
          {
            title: 'Bearbeiten',
            icon: ['fas', 'pencil-alt'],
            action: actions.editCareStay,
          },
          {
            title: 'Annehmen',
            icon: ['fad', 'user-check'],
            action: actions.acceptCareStay,
          },
          {
            title: 'Ablehnen',
            icon: ['fad', 'user-slash'],
            action: actions.rejectCareStay,
          },
          {
            title: 'Abbrechen',
            icon: ['far', 'ban'],
            action: actions.cancelCareStay,
          }
        ]
      }
    },
  ]
}

export const providerCareStayColumns = (actions: { [key: string]: Function }) => {
  return [
    {
      component: () => import("@/components/shared/table/cells/ValueCell.vue"),
      title: "Wechsel",
      key: 'attributes.isSwap',
    },
    {
      component: () => import("@/components/shared/table/cells/ValueCell.vue"),
      title: "Status",
      key: 'attributes.stage',
    },
    {
      component: () => import("@/components/shared/table/cells/LinkCell.vue"),
      title: "BK",
      key: 'meta.cGName',
      options: {
        hrefFunction: (data: any) => `/care_giver_instances/${data.item.relationships?.careGiverInstance?.data?.id}`
      }
    },
    {
      component: () => import("@/components/shared/table/cells/ValueCell.vue"),
      title: "Rechnung Start",
      key: 'attributes.billStart',
      sortable: true,
      options: {type: 'date'}
    },
    {
      component: () => import("@/components/shared/table/cells/ValueCell.vue"),
      title: "Rechnung Ende",
      key: 'attributes.billEnd',
      options: {type: 'date'},
    },
    {
      component: () => import("@/components/shared/table/cells/ValueCell.vue"),
      label: "Ankunft",
      title: "Ankunft",
      key: 'attributes.arrival',
      sortable: true,
      options: { type: 'date' }
    },
    {
      component: () => import("@/components/shared/table/cells/ValueCell.vue"),
      label: "Abreise",
      title: "Abreise",
      key: 'attributes.departure',
      sortable: true,
      options: { type: 'date' }
    },
    {
      component: () => import("@/components/shared/table/cells/LinkCell.vue"),
      title: "Vertrag",
      key: 'meta.contract',
      options: {
        contentFunction: (data: any) => {
          return (data.item.attributes.isSwap ? 'Zum Vertrag' : 'Neuvorschlag')
        },
        hrefFunction: (data: any) => {
          if (data.item.attributes.isSwap) return `/contracts/${data.item.meta?.contractId}`
          return `/visors/${data.item.meta?.visorId}`
        },
        cellClassFunction: (data: any) => (data.item.attributes.isSwap ? '' : 'text-warning')
      }
    },
    {
      component: () => import("@/components/shared/table/cells/ValueCell.vue"),
      label: "Preis",
      title: "Preis",
      key: 'attributes.price',
    },
    {
      component: () => import("@/components/shared/table/cells/ValueCell.vue"),
      label: "Prov",
      title: "Provision",
      key: 'attributes.provSeller',
      options: {
        contentFunction: (data: any) => {
          const { provSeller, provPfs, discountSeller } = data.item.attributes
          return provSeller + provPfs - (discountSeller || 0)
        }
      }
    },
    {
      component: () => import("@/components/shared/table/cells/ValueCell.vue"),
      label: "2PHH",
      title: "2PHH",
      key: 'attributes.dubHh',
    },
    {
      component: () => import("@/components/shared/table/cells/ValueCell.vue"),
      label: "2PBP",
      title: "2PBP",
      key: 'attributes.dubCr',
    },
    {
      component: () => import("@/components/shared/table/cells/ValueCell.vue"),
      label: "Nachtdienst",
      title: "Nachtdienst",
      key: 'attributes.nightCare',
    },
    {
      component: () => import("@/components/shared/table/cells/ValueCell.vue"),
      label: "Führerschein",
      title: "Führerschein",
      key: 'attributes.driversLicense',
    },
    {
      component: () => import("@/components/shared/table/cells/ButtonsCell.vue"),
      key: "actions",
      title: "Actions",
      sortable: false,
      options: {
        buttons: [
          {
            title: 'Monatspreis',
            icon: ['fas', 'info-circle'],
            action: actions.showMonthlyPrice,
          },
          {
            title: 'Bearbeiten',
            icon: ['fas', 'pencil-alt'],
            action: actions.editCareStay,
          },
          {
            title: 'Bestätigen',
            icon: ['fad', 'user-check'],
            action: actions.confirmCareStay,
          },
          {
            title: 'Abbrechen',
            icon: ['fad', 'user-slash'],
            action: actions.cancelCareStay,
          }
        ]
      }
    },
  ]
}

export const providerCareStayIndexColumns = (actions: { [key: string]: Function }) => {
  return [
    {
      component: () => import("@/components/shared/table/cells/ValueCell.vue"),
      title: "Status",
      key: 'attributes.stage',
    },
    {
      component: () => import("@/components/shared/table/cells/LinkCell.vue"),
      title: "BK",
      key: 'attributes.cg.attributes.lastName',
      options: {
        contentFunction: (data: any) => `${data.item.attributes.cg?.attributes?.firstName} ${data.item.attributes.cg?.attributes?.lastName}`.trim(),
        hrefFunction: (data: any) => `/care_giver_instances/${data.item.relationships?.careGiverInstance?.data?.id}`
      }
    },
    {
      component: () => import("@/components/shared/table/cells/ValueCell.vue"),
      title: "Erstellt",
      key: 'attributes.createdAt',
      options: {type: 'datetime'}
    },
    {
      component: () => import("@/components/shared/table/cells/ValueCell.vue"),
      title: "Vorschlag",
      key: 'attributes.presentedAt',
      options: {type: 'datetime'}
    },
    {
      component: () => import("@/components/shared/table/cells/ValueCell.vue"),
      title: "Rechnung Start",
      key: 'attributes.billStart',
      sortable: true,
      options: {type: 'date'}
    },
    {
      component: () => import("@/components/shared/table/cells/ValueCell.vue"),
      title: "Rechnung Ende",
      key: 'attributes.billEnd',
      options: {type: 'date'},
    },
    {
      component: () => import("@/components/shared/table/cells/ValueCell.vue"),
      label: "Ankunft",
      title: "Ankunft",
      key: 'attributes.arrival',
      sortable: true,
      options: { type: 'date' }
    },
    {
      component: () => import("@/components/shared/table/cells/ValueCell.vue"),
      label: "Abreise",
      title: "Abreise",
      key: 'attributes.departure',
      sortable: true,
      options: { type: 'date' }
    },
    {
      component: () => import("@/components/shared/table/cells/LinkCell.vue"),
      title: "Vertrag",
      key: 'meta.contract',
      options: {
        contentFunction: (data: any) => {
          return (data.item.attributes.isSwap ? 'Zum Vertrag' : 'Neuvorschlag')
        },
        hrefFunction: (data: any) => {
          if (data.item.attributes.isSwap) return `/contracts/${data.item.attributes?.contract?.id}`
          return `/visors/${data.item.relationships?.visor?.data?.id}`
        },
        cellClassFunction: (data: any) => (data.item.attributes.isSwap ? '' : 'text-warning')
      }
    },
    {
      component: () => import("@/components/shared/table/cells/ValueCell.vue"),
      label: "Preis",
      title: "Preis",
      key: 'attributes.price',
    },
    {
      component: () => import("@/components/shared/table/cells/ValueCell.vue"),
      label: "Prov",
      title: "Provision",
      key: 'attributes.provSeller',
      options: {
        contentFunction: (data: any) => {
          const { provSeller, provPfs, discountSeller } = data.item.attributes
          return provSeller + provPfs - discountSeller
        }
      }
    },
    {
      component: () => import("@/components/shared/table/cells/ValueCell.vue"),
      label: "2PHH",
      title: "2PHH",
      key: 'attributes.dubHh',
    },
    {
      component: () => import("@/components/shared/table/cells/ValueCell.vue"),
      label: "2PBP",
      title: "2PBP",
      key: 'attributes.dubCr',
    },
    {
      component: () => import("@/components/shared/table/cells/ValueCell.vue"),
      label: "Nachtdienst",
      title: "Nachtdienst",
      key: 'attributes.nightCare',
    },
    {
      component: () => import("@/components/shared/table/cells/ValueCell.vue"),
      label: "Führerschein",
      title: "Führerschein",
      key: 'attributes.driversLicense',
    },
    {
        component: () => import("@/components/shared/table/cells/ValueCell.vue"),
        label: "Absage-/AbbruchsGrund",
        title: "Absage-/AbbruchsGrund",
        key: 'rejectionReason',
        options: {
          contentFunction: (data: any) => {
            const atts = data.item.attributes
            return (atts.stage === 'Abgelehnt' ? atts.rejectionReason : atts.cancelReason || '')
          }
        }
      },
    // {
    //   component: () => import("@/components/shared/table/cells/ButtonsCell.vue"),
    //   key: "actions",
    //   title: "Actions",
    //   sortable: false,
    //   options: {
    //     buttons: [
    //       {
    //         title: 'Bearbeiten',
    //         icon: ['fas', 'pencil-alt'],
    //         action: actions.editCareStay,
    //       },
    //       {
    //         title: 'Bestätigen',
    //         icon: ['fad', 'user-check'],
    //         action: actions.confirmCareStay,
    //       },
    //       {
    //         title: 'Abbrechen',
    //         icon: ['fad', 'user-slash'],
    //         action: actions.cancelCareStay,
    //       }
    //     ]
    //   }
    // },
  ]
}
